import React from 'react';
import './Bottem.css'

const Bottem = () => {
  return (
    <footer className="footer">
      Copyright &#169; 2023 Anand. All Right Received.
    </footer>
  )
}

export default Bottem